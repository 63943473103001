@import 'variables';

// FONTS
@import url(https://fonts.googleapis.com/css?family=Lato:300,400);

// UTIL
@import 'util/_mixins.scss';
@import 'util/_pattern.scss';

// BOWER COMPONENTS
@import '../../bower_components/normalize-scss/normalize.scss';
@import '../../bower_components/material-design-iconic-font/scss/material-design-iconic-font.scss';

// UIKIT MIXINS
@import '../../bower_components/uikit/scss/uikit-mixins';

// UIKIT AFTER CORE & Autocomplete
//@import '../../bower_components/uikit/scss/uikit';
// LESS related
@import "../../bower_components/uikit/scss/core/variables.scss";

// Defaults
@import "../../bower_components/uikit/scss/core/base.scss";

// Layout
@import "../../bower_components/uikit/scss/core/grid.scss";

// Elements
@import "../../bower_components/uikit/scss/core/list.scss";
@import "../../bower_components/uikit/scss/core/description-list.scss";
@import "../../bower_components/uikit/scss/core/table.scss";
@import "../../bower_components/uikit/scss/core/form.scss";

// Need to be loaded last
@import "../../bower_components/uikit/scss/core/text.scss";
@import "../../bower_components/uikit/scss/core/utility.scss";
@import "../../bower_components/uikit/scss/core/flex.scss";

@import '../../bower_components/uikit/scss/components/autocomplete';

// BASE
@import 'base/_general.scss';
@import 'base/_icons.scss';

// COMPONENTS
@import 'layout/_base.scss';

// COMPONENTS
@import 'components/_form.scss';
@import 'components/_icon.scss';

// STATES
@import 'states/_global.scss';
