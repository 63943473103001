.wstp-icon {
	position: relative;
    top: -48px;
    left: 226px;
    font-size: 32px;
    color: #a9a9a9;
}


.logo {
	width: 40px;
	font-size: 40px;
	margin-bottom: 40px;
}
