// Stacked Icons
// -------------------------

.#{$zmdi-css-prefix}-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.#{$zmdi-css-prefix}-stack-1x, .#{$zmdi-css-prefix}-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.#{$zmdi-css-prefix}-stack-1x {
  line-height: inherit;
}
.#{$zmdi-css-prefix}-stack-2x {
  font-size: 2em;
}
.#{$zmdi-css-prefix}-inverse {
  color: $zmdi-inverse;
}