.clearfix {
    @extend %clearfix;
}

/*
 * Hide completely
 */
.hidden {
    display: none;
}

/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 */
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element to be focusable
 * when navigated to via the keyboard.
 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.pull-left {
    display: block;
    float: left;
}

.pull-right {
    display: block;
    float: right;
}

/*
 * Hide visually and from screenreaders, but maintain layout
 */
.invisible {
    visibility: hidden;
}
