html, body {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	min-height: 100%;
	margin: 0;
	padding: 0;

}

html, button, input, textarea {
	-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
		-ms-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

* {
	box-sizing: border-box;
}

img {
	max-width: 100%;
}


.font-awesome {
	font-family: FontAwesome;
    font-size: 22px;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    line-height: 12px;
}


.wstp-remove {
	position: absolute;
    width: 0px;
    height: 0px;
}