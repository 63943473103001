.wstp-form--centered {
	max-width:720px!important;
}

hr {
	margin: 5px 0 0 0;
}

input {
	text-align: center;
	background: #fafafa;
	border:0;
	margin-top:5px;
	max-width: 100%;
}

button {
	float: right;
	text-align: center;
	color:#A9A9A9;
	font-size: 16px;
	margin-top:5px;
	min-height: auto;
	background: #fafafa;
	border:0;
	padding: 14px 54px;
}

.dropzone {
	color:#A9A9A9;
	font-size: 16px;
	margin-top:5px;
	min-height: auto;
	background: #fafafa;
	border:0;
	padding: 14px 54px;
}
.dropzone.dz-clickable { cursor: pointer; }
.dropzone.dz-clickable * { cursor: default; }
.dropzone.dz-clickable .dz-message, .dropzone.dz-clickable .dz-message * { cursor: pointer; }
.dropzone.dz-started .dz-message { display: none; }
.dropzone.dz-drag-hover { border-style: solid; }
.dropzone.dz-drag-hover .dz-message { opacity: 0.5; }
.dropzone .dz-message { text-align: center; margin: 2em 0; }
.dropzone .dz-preview { vertical-align: center; margin: 16px;}
