// Spinning Icons
// --------------------------

.#{$zmdi-css-prefix}-spin {
   animation: zmdi-spin 1.5s infinite linear;
}

.#{$zmdi-css-prefix}-spin-reverse {
  animation: zmdi-spin-reverse 1.5s infinite linear;
}

// Spin
@keyframes zmdi-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

// Spin Reverse
@keyframes zmdi-spin-reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-359deg);
  }
}

