// Bordered icons
// -------------------------

.#{$zmdi-css-prefix}-border {
  padding: .1em .25em;
  border: solid .1em $md-border-color;
  border-radius: 2px;
}

.#{$zmdi-css-prefix}-border-circle {
  padding: .1em .25em;
  border: solid .1em $md-border-color;
  border-radius: 50%;
}
